import { createApiClient } from '@/services/_auth_interceptors';
import Organization from '@/interfaces/Organization';
import User from '@/interfaces/User';

const config = require('../../oidc/index');

const Api = createApiClient(config.oidcSettings.policyServerApiBaseURL);

export default {
  async getUsers(searchText) {
    let url = 'users/all';
    if (searchText) {
      url = `users/all?searchText=${searchText}`;
    }

    return Api.get(url);
  },
  async getOrganizations(searchText, isActive) {
    const paramsObj = { searchText: '', isActive: '' };
    const searchParams = new URLSearchParams(paramsObj);
    if (searchText) {
      searchParams.set('searchText', searchText);
    }
    searchParams.set('isActive', isActive);
    const apiOrgnizations = (await Api.get(`organizations?${searchParams.toString()}`)).data.items;
    const organizations = apiOrgnizations.map((x) => new Organization(x));
    return organizations;
  },
  async getOrganizationUsers(organizationId, searchText, isActive) {
    const paramsObj = { organizationId: '', searchText: '', isActive: '' };
    const searchParams = new URLSearchParams(paramsObj);
    if (searchText) {
      searchParams.set('searchText', searchText);
    }
    if (organizationId) {
      searchParams.set('organizationId', organizationId);
    }
    searchParams.set('isActive', isActive);

    const apiUsers = (await Api.get(`users?${searchParams.toString()}`)).data.items;
    const users = apiUsers.map((x) => new User(x));
    return users;
  },
  async getOrganization(id) {
    let url = 'organizations';
    if (id) {
      url = `organizations/${id}`;
    }
    const apiOrganization = (await Api.get(url)).data;
    const organization = new Organization(apiOrganization);
    return organization;
  },
};
