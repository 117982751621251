<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      max-width="600px"
      @click:outside="$emit('@close')"
    >
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col class="text-center">
              <v-card-title>Uwaga!</v-card-title>
              <v-card-subtitle>Usuwasz sposób wysyłki!</v-card-subtitle>
              <v-card-text class="pb-0">
                Nie będziesz mógł wysłać przesyłki korzystając z tej usługi kuriera.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="my-6" />
          <v-card-actions>
            <v-row>
              <v-col
                offset="4"
                cols="4"
              >
                <v-btn
                  elevation="0"
                  small
                  outlined
                  color="primary lighten-1"
                  @click="$emit('@close')"
                >
                  Anuluj
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  elevation="0"
                  small
                  color="danger base"
                  style="color: white; font-weight:normal;"
                  @click="$emit('@delete')"
                >
                  Usuń
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: { isOpen: Boolean },
  data: () => ({
    value: false,
  }),
  watch: {
    isOpen(newValue) {
      this.value = newValue;
    },
  },
  mounted() {
    this.value = this.isOpen;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";

.v-card{
  border-left: 5px solid $danger;

  &__title{
    color:$danger !important;
    font-weight: 700;
    font-size:34px;
  }

  &__subtitle{
    font-weight: 700;
    font-size:24px;
    text-align: left;
    margin-top: 1px;
  }

  &__text{
    font-size:16px;
    text-align: left;
    margin-top: 5px;
  }
}

.v-btn{
  width: 160px;
  min-height: 48px;
  font-size: 14px !important;
}

</style>
