import { claimTypes } from '@/constants/Claims';

export default class SpeditionClaims {
  constructor(claims) {
    this.allowedCouriers = [];
    this.originalAllowedCouriers = [];
    if (claims) {
      this.allowedCouriers = claims.filter((x) => x.type === claimTypes.ALLOWED_COURIER)
        .map((x) => x.value);
      this.originalAllowedCouriers = [...this.allowedCouriers];
    }

    this.isActive = false;
    if (this.allowedCouriers.length > 0) {
      this.isActive = true;
    }
  }
}
